<template>
    <div class="analytic_wrapper flex">
        <div class="content grid gap-11 fs-16 fw-700 flex-shrink-0 w-1/3">
            <div class="flex gap-4 justify-between items-center">
                <div class=" cursor-pointer hover:text-primary-four">Total Content Views:</div>
                <div class="flex gap-4 items-center">
                    <span>1,254</span>
                    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18 16H15V6H18V16ZM13 16H10V3H13V16ZM8 16H5V0H8V16ZM3 16H0V8H3V16Z" fill="#2B80AD"/>
                    </svg>
                </div>
            </div>
            <div class="flex gap-4 justify-between items-center">
                <div class=" cursor-pointer hover:text-primary-four">Appreciations:</div>
                <div class="flex gap-4 items-center">
                    <span>24,565</span>
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V12C1 11.4696 1.21071 10.9609 1.58579 10.5858C1.96086 10.2107 2.46957 10 3 10H6M13 8V4C13 3.20435 12.6839 2.44129 12.1213 1.87868C11.5587 1.31607 10.7956 1 10 1L6 10V21H17.28C17.7623 21.0055 18.2304 20.8364 18.5979 20.524C18.9654 20.2116 19.2077 19.7769 19.28 19.3L20.66 10.3C20.7035 10.0134 20.6842 9.72068 20.6033 9.44225C20.5225 9.16382 20.3821 8.90629 20.1919 8.68751C20.0016 8.46873 19.7661 8.29393 19.5016 8.17522C19.2371 8.0565 18.9499 7.99672 18.66 8H13Z" stroke="#2B80AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div class="flex gap-4 justify-between items-center">
                <div class=" cursor-pointer hover:text-primary-four">Contributor you follow:</div>
                <div class="flex gap-4 items-center">
                    <span>354</span>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z" stroke="#2B80AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.9984 19.0004L14.6484 14.6504" stroke="#2B80AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div class="flex gap-4 justify-between items-center">
                <div class=" cursor-pointer hover:text-primary-four">Followers on Instrat360:</div>
                <div class="flex gap-4 items-center">
                    <span>578</span>
                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.6094 15C15.4754 15 18.6094 11.866 18.6094 8C18.6094 4.13401 15.4754 1 11.6094 1C7.74338 1 4.60938 4.13401 4.60938 8C4.60938 11.866 7.74338 15 11.6094 15Z" stroke="#2B80AD" stroke-width="1.5" stroke-miterlimit="10"/>
                        <path d="M1 21.124C2.07577 19.2619 3.62261 17.7158 5.48512 16.6408C7.34764 15.5659 9.46024 15 11.6107 15C13.7612 15 15.8738 15.566 17.7362 16.6409C19.5987 17.7159 21.1455 19.2621 22.2213 21.1242" stroke="#2B80AD" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
        </div>
        <div ref="chartBox" class="chart w-2/3">
            <VueApexCharts  width="550"  height="200" :type="line" :options="options" :series="series" />
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import VueApexCharts from 'vue-apexcharts'

    const chartBox = ref()
    const series = [{
        name: 'series-1',
        data: [3000, 2700, 2900, 2000, 2500, 1000]
    }]

    const options = {
        chart: {
          id: 'ChartId'
        },
        xaxis: {
          categories: ['Aut.', 'Sept.', 'Nov.', 'Dec.', 'Jan.', 'Feb.']
        },
        yaxis: {
          categories: [0, 1000, 2000, 3000]
        }
    }
</script>
<style scoped>
    .content{
        height: 220px;
        overflow-y: auto;
    }
    /* @media all and (max-width: 1850px){
        .analytic_wrapper{
            display: flex;
            overflow-x: auto;
            gap: 32px;
        }
        .analytic_wrapper .content{
            width: fit-content;
        }
    } */
</style>
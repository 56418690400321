<template>
    <div>
        <div class="plansBillingWrapper mb-10 flex gap-8 items-center">
            <CurrentPlan />
            <CurrentPlanDetails v-if="plan.name == 'Free'" />
            <NextBilling v-else />
            <BillingCard />
        </div>
        <div style="height: 470px" class="overflow-y-auto">
            <DataTable
                :options="options"
                :items="subscriptions"
                :headers="headers"
            >
                <template #created_at="{ item }">
                    {{ format(new Date(item.created_at), 'MM/dd/yyyy') }}
                </template>
                <template #stripe_status="{ item }">
                    <PaymentStatus :item="item" />
                </template>
                <template #amount="{ item }">
                    {{ getTotalPrice(item) }}
                </template>
                <template #plan="{ item: { plan } }">
                    {{ plan.title }}
                </template>
                <template #action>
                    Download
                </template>
            </DataTable>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, computed, watchEffect } from 'vue'
    import DataTable from '@/elements/table/DataTable';
    import useDatatable from '@/elements/table/useDatatable';
    import CurrentPlan from '@/components/workspace/billing/CurrentPlan';
    import NextBilling from '@/components/workspace/billing/NextBilling';
    import BillingCard from '@/components/workspace/billing/BillingCard';
    import PaymentStatus from '@/components/workspace/billing/PaymentStatus';
    import CurrentPlanDetails from '@/components/workspace/billing/CurrentPlanDetails.vue';
    import { format } from 'date-fns';
    import { get } from 'lodash'
    // import useAuth from '@/composable/useAuth';
    import useSubscription from '@/composable/useSubscription';
    // const { plan } = useAuth();
    const { getSubscriptions, getTotalPrice } = useSubscription();
    const subscriptions = ref([]);
    const workspace = computed(() => store.getters['workspace/activeWorkspaceObj']);
    const plan = computed(() =>  get(workspace.value, 'subscription.plan', {}));

    watchEffect( async () => {
        if(!workspace.value) {
            return;
        }
        subscriptions.value = await getSubscriptions(workspace.value.id);
    });
    const headers = [
        {
            visible: true,
            text: 'Billing date',
            value: 'created_at'
        },{
            visible: true,
            text: 'Billing status',
            value: 'stripe_status'
        },{
            visible: true,
            text: 'Editor',
            value: 'quantity'
        },{
            visible: true,
            text: 'Amount',
            value: 'amount'
        },{
            visible: true,
            text: 'Plan',
            value: 'plan'
        },{
            visible: true,
            text: 'Action',
            value: 'action'
        }
    ]

    const options = {
        hasCheckbox: true
    }
</script>
<script>
    import store  from '@/store';
    export default {
        computed: {
            workspace() {
                return store.getters['workspace/activeWorkspaceObj']
            }
        },
        async beforeRouteEnter(to, from, next) {
            let auth = await store.dispatch('getUser');
            if(!auth.plan) {
                next({
                name: 'subscription.plans',
                params: {
                    workspace_id: workspace.id || null
                }
                });
            }
            next();
        }
    }
</script>
<style scoped>
.plans{
    filter: drop-shadow(0px 2px 8px rgba(44, 52, 65, 0.1));
    width: 455px;
    height: 214px;
    border: 1px solid #C9C9C9;
    padding: 30px;
}


/* @media all and (max-width: 1850px) and (min-width: 1300px){
    .plansBillingWrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
    }
    .plansBillingWrapper .plans {
        max-width: 600px !important;
        width: 100% !important;
    }
} */
</style>
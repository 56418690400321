<template>
  <div>
      <div class="flex justify-between items-end pr-18px mb-6">
        <div class="mt-14">
          <RouteNavigator :routeData="routeData"/>
        </div>
        <div class="flex items-center gap-6 relative">
          <select v-if="$route.name == 'workspace.members'"  v-model="value" @change="onChangeProject" name="" id="" class="fs-14 text-secondary-six bg-transparent pr-select-dropdown">
            <option v-for="project in projects" :key="project.id" :value="project.id">{{ project.name }}</option>
          </select>
          <!-- <InputSelect
            v-if="$route.name == 'workspace.members'"
            classes='border-none fw-400 fs-14 text-secondary-six pr-select-dropdown bg-transparent w-40 rounded-sm py-1'
            :items="projects"
            nameKey="name"
            @change="onChangeProject"
            v-model="value"
          /> -->
          <!-- <MessageIcon class="cursor-pointer w-4 h-4" />
          <BellIcon @click.native="notification = !notification" class="cursor-pointer w-4 h-4" /> -->
          <div class="absolute dotted-notification bg-primary-five" v-if="unreadNotification.length"></div>
          <popup v-if="notification" />
        </div>
      </div>
      <div class="pr-18px">
        <router-view></router-view>
      </div>
  </div>
</template>

<script setup>
  import store from '@/store'
  import { ref, computed, onMounted } from 'vue'
  import RouteNavigator from "@/elements/navbar/RouteNavigator";
  import routeData from "@/mock/routeData";
  import InputSelect from '@/elements/inputs/InputSelect.vue'
  import useDatatable from '@/elements/table/useDatatable'
  import Popup from "@/components/notification/Popup";
  const notification  = ref(false)
  const projects      = computed(() => [{id: null, name: 'Workspace'}, ...store.getters['workspace/workspaceSettingsInfo']])
  const notifications = computed(() => store.getters['notification/notifications'])

  const unreadNotification = computed(() => {
    if(!notifications.value) return []
    let unread = notifications.value.filter(item => !item.is_read)
    return unread && unread.length > 0
  })

  const { removeIds } = useDatatable()

  const value = null

  function onChangeProject(e) {
    removeIds()
    store.commit('workspace/setProjectId', e.target.value)
  }
</script>

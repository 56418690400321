import axios from 'axios';
import store from '@/store'

export function UseProfile(){
    const profileInfo = async () => {
        return store.dispatch("profile/fetchProfileData")
    }

    const uploadProfileImage = async (payload) => {
        return store.dispatch("profile/updateProfileImage", payload)
    }

    const updateProfileData = (biography) => {
        return store.dispatch("profile/updateProfileData", biography)
    }

    return {
        profileInfo,
        uploadProfileImage,
        updateProfileData
    }
}

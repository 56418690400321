<template>
    <div>
        <!-- {{ selected }} -->
        <div>
            <DataTable
                v-if="members.length"
                :options="options"
                :items="members"
                :headers="headers"
            >
                <template v-if="selected.length" #headers>
                    <div class="relative pl-4 mt-3 mb-3">
                        <Checkbox
                            :totalItem="members.length"
                            :totalSelected="selected.length"
                            @selectAll="selectAll(members)"
                            @removeAll="removeIds()"
                        />
                        <FloatAction
                            class="absolute"
                            :totalItem="members.length"
                            :totalSelected="selected.length"
                            @deleteMultiple="deleteMultiple"
                            @selectAll="selectAll(members)"
                            @removeAll="removeIds()"
                        />
                    </div>
                </template>
                <template #header-action>
                    <div class="flex items-center gap-2">
                        <span>Action</span>
                        <PlusCircleIcon
                            v-show="canCreate(company.cans)"
                            v-if="activeWorkspaceId"
                            @click.native="addPeople"
                            class="w-4 h-4 opacity-50 hover:opacity-80 cursor-pointer"
                        />
                    </div>
                </template>
                <template #row="{item}">
                    <td class="text-primary-one fs-14 fw-400 pl-6">
                        <div v-if="item.user" class="flex items-center gap-2">
                            <img class="rounded-full w-8 h-8 object-contain" :src="getImage(item.user)" alt="">
                            {{ item.user.first_name }} {{ item.user.last_name }}
                        </div>
                      <div v-else class="flex items-center gap-2">
                        Not accepted yet
                      </div>
                    </td>
                    <td class="text-secondary-six fs-14 fw-400 px-6">
                        <div v-if="item.user">
                            {{ item.user.email }}
                        </div>
                        <div v-else>{{ item.email }}</div>
                    </td>
                    <td class="text-secondary-six fs-14 fw-400 px-4">
                        <p v-if="item.role == 1" class="fs-14 text-secondary-six pl-2">Admin</p>
                        <InputSelect
                            v-else
                            :classes="'border-none fw-400 fs-14 text-secondary-six pr-select-dropdown bg-transparent w-full rounded-sm py-1'"
                            :items="roles"
                            v-model="item.role"
                            :disabled="!canCreate(company.cans)"
                            @change="updateRole($event, item.id)"
                        />
                    </td>
                    <td class="text-secondary-six fs-14 fw-400 px-4">
                        <p class="fs-14 text-secondary-six">{{ roleActions[item.role - 1] }}</p>
                        <!-- <InputSelect
                            :classes="'border-none fw-400 fs-14 text-secondary-six pr-select-dropdown bg-transparent w-full rounded-sm py-1'"
                            :items="[
                                {
                                    title: roleActions[item.role - 1]
                                }
                            ]"
                        /> -->
                    </td>
                    <td class="text-secondary-six fs-14 fw-400 px-6">{{ $lodash.ago(item.created_at) }}</td>
                    <td class="text-secondary-six fs-14 fw-400 px-6">
                        <Action @onClickDelete="deleteSingleResponsible(item.id, company.id)" :class="{'pointer-events-none': !canDelete(company.cans)}" />
                    </td>
                </template>
            </DataTable>

        </div>
        <AddResponsibleModal
            v-if="company"
            @toggleModalMode="toggleModalMode"
            :dModal="dModal"
            :existingResponsible="allMembers"
            :projectId="selectedProjectId"
            :workspaceId="company.id"
        />
      <workspace-responsible-modal :dModal="eModal"
                                   :ref="'workspace_responsible'"
                                   @toggleEditModal="toggleEditModal" />
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue'
    import DataTable from '@/elements/table/DataTable.vue'
    import InputSelect from '@/elements/inputs/InputSelect.vue'
    import useDatatable from '@/elements/table/useDatatable'
    import useRole from '@/composable/useRole'
    import Action from '@/elements/table/Action'
    import Checkbox from '@/elements/table/Checkbox'
    import FloatAction from '@/elements/table/FloatAction.vue'
    import AddResponsibleModal from '@/components/workspace/project/AddResponsibleModal'
    import store from '@/store'
    import WorkspaceResponsibleModal from "@/components/workspace/WorkspaceResponsibleModal";
    import usePermission from '@/composable/usePermission.js'
    const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()

    const { selected, removeIds, selectAll } = useDatatable()
    const {updateRole, deleteProjectResponsible, deleteWorkspaceResponsible, deleteProjectMultipleResponsible, deleteWorkspaceMultipleResponsible} = useRole()

    const defaultProfileImage = '/images/avatar.jpg'
    const roleActions = ref(['Can edit', 'Can edit', 'Can view', 'Can view'])
    const dModal = ref(false)
    // const isWorkspaceMember = ref(false)
    const activeWorkspaceId = ref()
    const eModal = ref(false)
    const onlyResponsible = ref(true)

    const company = computed(() => {
        return store.getters['workspace/activeWorkspaceObj']
    })

    const projects = computed(() => {
        return store.getters['workspace/workspaceSettingsInfo']
    })

    const selectedProjectId = computed(() => {
        return store.state.workspace.selectedProjectId
    })


    const workspaceMembers = computed(() => {
        let workspaces = store.getters['workspace/activeWorkspaceObj']
        activeWorkspaceId.value = workspaces?.id
        return workspaces ? workspaces.responsibles : []
    })

    const selectedProject = computed(()=>{
        return projects.value.find(item => parseInt(item.id) === parseInt(selectedProjectId.value))
    })

    const isProjectSelected = computed(()=>{
        return Boolean(projects.value.find(item => parseInt(item.id) === parseInt(selectedProjectId.value)))
    })

    const members = computed(() =>
    {
        if(selectedProject.value) {
            // return project.responsibles.filter(responsible => parseInt(responsible.user_id) !== parseInt(project.owner_id));
            return selectedProject.value?.responsibles;
        }
        return workspaceMembers.value
    })

    const allMembers = computed(() => {
        if(selectedProject.value) {
            return selectedProject.value?.responsibles
        }
        return []
    })

    const toggleModalMode = () => {
        dModal.value = false
    }



    const headers = [
        {
            visible: true,
            text: 'Name',
            value: 'name'
        },{
            visible: true,
            text: 'Email',
            value: 'email'
        },{
            visible: true,
            text: 'Role',
            value: 'role'
        },{
            visible: true,
            text: 'Permission',
            value: 'role'
        },{
            visible: true,
            text: 'Invited',
            value: 'created_at'
        },{
            visible: true,
            text: 'Action',
            value: 'action'
        }
    ]

    const roles = [
        // { id: 1, title: 'Admin' },
        { id: 2, title: 'Editor' },
        { id: 4, title: 'Viewer' }
    ]

    const workspaceOptions = {
        hasCheckbox: true
    }

    const options = {
        hasCheckbox: true
    }

    function getImage(user) { return  user?.image ? user.image.file_path : defaultProfileImage }

    const addPeople = ()=> {
        if(isProjectSelected.value){
            dModal.value = true;
        } else if(activeWorkspaceId.value) {
          eModal.value = true;
        } else {
          alert('Please select a project or workspace');
        }
    }

    const deleteSingleResponsible = (itemId, workspaceId)=>{
        if(isProjectSelected.value){
            deleteProjectResponsible(itemId, workspaceId)
            return;
        }
        deleteWorkspaceResponsible(workspaceId, itemId)
    }

    function deleteMultiple()
    {
        if(isProjectSelected.value)
        {
            deleteProjectMultipleResponsible({
                projectId: selectedProjectId.value,
                ids:selected.value
            })
            removeIds()
            return;
        }

        deleteWorkspaceMultipleResponsible(activeWorkspaceId.value, selected.value)
        removeIds()
    }

    function toggleEditModal() {
      eModal.value = !eModal.value;
    }
</script>

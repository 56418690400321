export default [
    {
        title: 'My Profile',
        routeName: 'workspace.myProfile'
    },
    {
        title: 'Setting',
        routeName: 'workspace.settings'
    },
    {
        title: 'Members',
        routeName: 'workspace.members'
    },
    {
        title: 'Plans & Billing',
        routeName: 'workspace.plansBilling'
    },
    {
        title: 'Contribution System',
        routeName: 'workspace.contributionSystem'
    },
]
<template>
    <div class="plans py-3 px-4 rounded border">
        <span class="fw-600 fs-14 mb-1" style="color: #616161">Payment info</span>
        <div class="flex justify-between items-center mb-6">
            <div class="flex gap-3 items-center">
                <h2 class="fw-600 fs-20 text-primary-one">**** **** **** {{ auth.pm_last_four }}</h2>
                <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="20" rx="2" fill="#4A529C"/>
                    <path d="M12.2656 13.7902L13.6879 6.25H15.9102L14.5324 13.7902H12.2656Z" fill="#111431"/>
                    <path d="M12.2656 13.7902L14.0879 6.25H15.9102L14.5324 13.7902H12.2656Z" fill="white"/>
                    <path d="M22.5776 6.33512C22.1331 6.16756 21.422 6 20.5331 6C18.3108 6 16.7107 7.04726 16.7107 8.55531C16.7107 9.68634 17.8219 10.2728 18.7108 10.6498C19.5997 11.0268 19.8664 11.2782 19.8664 11.6133C19.8664 12.116 19.1552 12.3673 18.533 12.3673C17.6441 12.3673 17.1552 12.2416 16.3996 11.9484L16.0885 11.8227L15.7773 13.5402C16.3107 13.7497 17.2885 13.9592 18.3108 13.9592C20.6664 13.9592 22.222 12.9119 22.222 11.3201C22.222 10.4404 21.6442 9.77012 20.3108 9.22555C19.5108 8.84854 19.0219 8.63909 19.0219 8.26207C19.0219 7.92695 19.4219 7.59183 20.3108 7.59183C21.0664 7.59183 21.5998 7.7175 21.9998 7.88506L22.222 7.96884L22.5776 6.33512Z" fill="#111431"/>
                    <path d="M22.5776 6.33512C22.1331 6.16756 21.422 6 20.5331 6C18.3108 6 17.1107 7.04726 17.1107 8.55531C17.1107 9.68634 17.8219 10.2728 18.7108 10.6498C19.5997 11.0268 19.8664 11.2782 19.8664 11.6133C19.8664 12.116 19.1552 12.3673 18.533 12.3673C17.6441 12.3673 17.1552 12.2416 16.3996 11.9484L16.0885 11.8227L15.7773 13.5402C16.3107 13.7497 17.2885 13.9592 18.3108 13.9592C20.6664 13.9592 22.222 12.9119 22.222 11.3201C22.222 10.4404 21.6442 9.77012 20.3108 9.22555C19.5108 8.84854 19.0219 8.63909 19.0219 8.26207C19.0219 7.92695 19.4219 7.59183 20.3108 7.59183C21.0664 7.59183 21.5998 7.7175 21.9998 7.88506L22.222 7.96884L22.5776 6.33512Z" fill="white"/>
                    <path d="M26.3992 6.25C25.8658 6.25 25.4658 6.29189 25.2436 6.79457L21.9102 13.7902H24.3102L24.7547 12.5335H27.5992L27.8659 13.7902H29.9993L28.1326 6.25H26.3992ZM25.3769 11.2768C25.5103 10.8998 26.2659 9.05665 26.2659 9.05665C26.2659 9.05665 26.4436 8.59586 26.577 8.30262L26.7103 9.01476C26.7103 9.01476 27.1548 10.8998 27.2437 11.3187H25.3769V11.2768Z" fill="#111431"/>
                    <path d="M26.9325 6.25C26.3992 6.25 25.9992 6.29189 25.7769 6.79457L21.9102 13.7902H24.3102L24.7547 12.5335H27.5992L27.8659 13.7902H29.9993L28.1326 6.25H26.9325ZM25.3769 11.2768C25.5547 10.8579 26.2659 9.05665 26.2659 9.05665C26.2659 9.05665 26.4436 8.59586 26.577 8.30262L26.7103 9.01476C26.7103 9.01476 27.1548 10.8998 27.2437 11.3187H25.3769V11.2768Z" fill="white"/>
                    <path d="M8.17719 11.5333L7.95496 10.4441C7.55495 9.18743 6.26602 7.80505 4.84375 7.1348L6.84381 13.8372H9.24389L12.844 6.297H10.4439L8.17719 11.5333Z" fill="#111431"/>
                    <path d="M8.17719 11.5333L7.95496 10.4441C7.55495 9.18743 6.26602 7.80505 4.84375 7.1348L6.84381 13.8372H9.24389L12.844 6.297H10.8884L8.17719 11.5333Z" fill="white"/>
                    <path d="M2 6.25L2.40001 6.33378C5.24455 6.96213 7.20017 8.55396 7.95575 10.439L7.15572 6.87835C7.02238 6.37567 6.62237 6.25 6.13347 6.25H2Z" fill="#FFBC00"/>
                    <path d="M2 6.25C4.84454 6.87835 7.20017 8.51207 7.95575 10.3971L7.20017 7.42293C7.06683 6.92024 6.62237 6.62701 6.13347 6.62701L2 6.25Z" fill="#F7981D"/>
                    <path d="M2 6.25C4.84454 6.87835 7.20017 8.51207 7.95575 10.3971L7.4224 8.76342C7.28906 8.26073 7.11128 7.75805 6.48903 7.5486L2 6.25Z" fill="#ED7C00"/>
                </svg>
            </div>
        </div>
        <h2><span class="fw-400 fs-14" style="color: #616161;">Billing to</span></h2>
        <h2 class="fs-14 fw-600 text-primary-one mb-5">{{ auth.email }}</h2>

        <a href="javaScript:void(0)" class="mt-5 flex gap-3 items-center fs-14 fw-600 text-primary-four">
            Change payment method
        </a>
    </div>
</template>

<script setup>
        import useAuth from '@/composable/useAuth'
        let { auth } = useAuth();
</script>
<template>
    <div>
        <div class="user_projects grid grid-cols-4 self-start gap-7">
            <Card
                :id = '1'
                @openModal="openModal"
                width="w-full"
                title = "Document Activity Logs - xxxxx"
                bg = "https://images.unsplash.com/photo-1659990234229-21bd3c69ba06?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxMHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
                description="Complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company."
                :settings = "{download: 280, love: 125}" />
            <Card
                :id = '2'
                @openModal="openModal"
                width="w-full"
                title = "Risk Managment 101"
                bg = "https://images.unsplash.com/photo-1660006795957-1af9c6de897e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOXx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
                description="Complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company."
                :settings = "{download: 280, love: 125}" />
            <Card
                :id = '3'
                @openModal="openModal"
                width="w-full"
                title = "SME Innovation workshops"
                bg = "https://images.unsplash.com/photo-1660001547784-c13f484714be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzM3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
                description="Complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company."
                :settings = "{download: 280, love: 125}" />
            <Card
                :id = '4'
                @openModal="openModal"
                width="w-full"
                title = "Document Activity Logs - xxxxx"
                bg = "https://images.unsplash.com/photo-1660002561318-6ef0a0ae1f04?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60"
                description="Complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company."
                :settings = "{download: 280, love: 125}" />
        </div>
        
        <Modal v-model="showModal" >
            <ModalContent :closeModal="closeModal" />
        </Modal>
    </div>
</template>

<script setup>
    import ModalContent from '@/components/workspace/defaultTemplates/ModalContent.vue'
    import Modal from "@/elements/atom/Modal.vue";
    import Card from './Card.vue'
    import { ref } from 'vue'

    const showModal = ref(false)
    
    const closeModal = () => showModal.value = false
    const openModal  = (id) => 
    {
        showModal.value = true
    }
</script>


<style scoped>
/* @media all and (max-width: 1850px){
    .user_projects{
        grid-template-columns: repeat(4, minmax(214px, 1fr)) !important;
        overflow: auto;
    }
} */
</style>
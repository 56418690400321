<template>
    <ul class="plans py-3 px-4 rounded border ">
        <div class="overflow-y-auto space-y-5 h-full">
            <li v-for="(item, index) in plan.features" :key="index" class="flex gap-x-2">
            <img src="/images/icons/subscription/check.svg" alt="">
            <p class="fs-14 font-normal">{{ item.max_limit }} {{ item.name }}</p>
        </li>
        </div>
    </ul>
</template>

<script setup>
    import useAuth from '@/composable/useAuth'
    let { plan } = useAuth();
</script>
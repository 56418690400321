<template>
    <label class="block styled-select">
      <span class="inline-block" :class="[labelClasses]" v-if="label && outerLabel"> {{ label }} </span>
      <select
        class="panzoom-exclude"
        :class="[classes]"
        v-model="localValue"
    >
        <option v-if="label" :value="null" disabled selected>{{ label }}</option>
          <template  v-for="(item, index) in items">
            <slot v-bind="{item, index}">
              <option :key="index" :value="item[valueKey]" v-text="item[nameKey]"></option>
            </slot>
          </template>
        </select>
    </label>
</template>

<script>
export default {
    name: 'InputSelect',
    props: {
      items: {
        type: [Array, Object],
        required: true
      },
      nameKey: {
        type: String,
        default: 'title'
      },
      valueKey: {
        type: String,
        default: 'id'
      },
      value: {
        required: false
      },
      label: {
        type: String,
        required: false
      },
      outerLabel: Boolean,
      classes: {
        type: String,
        default: 'fw-500 fs-10 text-secondary-one pr-select-dropdown param-dropdown bg-transparent w-full i-border-1 border-secondary-five rounded-sm py-1'
      },
      labelClasses: {
        type: String,
        default: 'text-secondary-two fs-10 fw-500'
      }
    },
    computed: {
      localValue: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
          this.$emit('change', value)
        }
      }
    }
}
</script>

<style scoped>
  .param-dropdown:focus {
    border: 1px solid #C9C9C9;
  }
  .styled-select select {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
  }
  select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("/images/icons/chevron_down.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position-x: 97%;
  background-position-y: 5px;
  padding-left: 8px;
}
</style>

<template>
    <div class="flex items-center text text-primary-one fs-14 fw-600 gap-10">
        <router-link
            :class="[isActive(route.routeName, $route.name) ? 'opacity-100' : 'opacity-50']"
            v-for="route in routeData"
            :key="route.title"
            :to="{name: route.routeName}"
        >
            {{ route.title }}
        </router-link>
    </div>
</template>

<script setup>
    const props = defineProps({
    routeData: Array,
    });
    const isActive = (name, routeName) => (name == routeName)
</script>
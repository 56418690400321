<template>
    <div class="wrapper shadow rounded overflow-hidden" :class="width">

        <div class="relative body h-140px" :style="{background: `url(${bg_img})`}">
            <div class="overlay absolute top-0 left-0 w-full h-full grid place-content-center">
                <button @click="emit('openModal', id)" class="bg-primary-four shadow rounded py-2 px-4 text-white">See More</button>
            </div>
        </div>

        <div class="footer p-5 py-3 bg-primary-three">
            <h2 class="fs-16 fw-700 text-primary-one mb-1" style="min-height:42px">{{ title }}</h2>
            <p v-show="description != ''" class="fs-8 fw-400 text-secondary-one mb-4">{{ description }}</p>
            <div class="flex justify-between items-center">
                <h2 v-show="subtitle != ''" class="fs-14 fw-500 text-color-616161">{{ subtitle }}</h2>
                <div class="flex gap-4">
                    <div class="flex gap-1 items-center">
                        <DownloadBarIcon class="w-4 h-4" />
                        <span class="fs-13 fw-500 text-color-616161">{{ settings.download }}</span>
                    </div>
                    <div class="flex gap-1 items-center">
                        <IconHeart class="w-4 h-4" />
                        <span class="fs-13 fw-500 text-color-616161">{{ settings.love }}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import IconHeart from '@/components/workspace/icons/IconHeart.vue'
    import {ref} from 'vue'

    // eslint-disable-next-line no-undef
    const emit = defineEmits(["openModal"])

    // eslint-disable-next-line no-undef
    const props = defineProps({
        id: {
            type: Number
        },
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: 'w-377px'
        },
        bg: {
            type: String,
            default: '#ccc'
        },
        settings: {
            type: Object,
            default: () => {
                return {
                    download: 0,
                    love: 0
                }
            }
        }
    })

    const bg_img = ref(props.bg)
</script>

<style scoped>
    .shadow{
        box-shadow: 0px 1.85558px 7.42233px rgba(44, 52, 65, 0.1);
    }
    .wrapper:is(:hover, .active){
        box-shadow: 0px 1.85558px 7.42233px rgba(44, 52, 65, 0.1);
    }
    .body{
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        border-radius: 4px 4px 0 0;
    }
    .overlay{
        visibility: hidden;
        opacity: 0;
        background: rgba(255, 255, 255, 0.8);
        transition: 0.3s ease;
    }
    .body:hover .overlay{
        visibility: visible;
        opacity: 1;
    }
    .footer{
        border-radius: 0 0 4px 4px;
    }
</style>
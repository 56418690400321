<template>
  <modal name="project-role-modal" @closed="closeModal()" :height="'auto'" :classes="'bg-primary-three'">
    <div class="p-10">
      <div class="flex i-border-b-1 border-secondary-four">
        <h3 class="font-poppins text-primary-one i-border-b-1 border-primary-one opacity-100 fs-20 cursor-pointer ws-modal-item-selector">
          Add New People</h3>
      </div>
      <div class="i-mt-30">
        <div class="">
          <h4 class="font-poppins text-secondary-one fs-14">Collaborator</h4>
          <div class="flex justify-between items-center mt-2 relative">
            <div class="modal-collaborator flex-3 pr-3 relative">
              <input type="text"
                     class="bg-transparent font-poppins text-secondary-one fs-14 placeholder-secondary-two w-full px-4 h-10 i-border-1 border-solid border-primary-one rounded"
                     :placeholder="placeholder.responsible" @click="placeholder.responsible = ''"
                     v-click-outside="outsideResponsible"
                     v-model="responsible.value">
              <div class="ws-modal-role-selector bg-transparent absolute">
                <select
                    class="fs-14 font-poppins text-secondary-one ws-select-dropdown bg-transparent"
                    v-model="responsible.role">
                  <option v-for="(role, index) in roles" :key="index" :value="role.value"
                          class="">{{ role.title }}
                  </option>
                </select>
              </div>
            </div>
            <button
                class="font-poppins fs-14 fw-600 h-10 w-40 flex-1 text-primary-one rounded i-border-1 border-secondary-one bg-transparent"
                @click="addNewResponsible()" :disabled="loading">Add
            </button>
          </div>
          <div class="collaborator-suggestion-box" v-if="getUsers.length > 0 && responsible.value">
            <ul>
              <li v-for="(user, index) in getUsers" :key="index"
                  class="search-suggestion fs-14 font-poppins px-4 py-2 text-primary-one cursor-pointer"
                  @click="chooseUser(user)">
                {{ getUserName(user) }}
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-collaborators mt-2">
          <span v-for="(responsible, index) in responsibles" :key="index" :title="getRole(responsible.role)"
                class="fs-14 text-primary-one font-poppins mr-4 px-4 mt-4 pt-1 rounded i-border-1 border-primary-one">
              {{ responsible.value }}
            <span class="ml-2 fs-12 cursor-pointer"
                  @click="removeResponsible(index)" style="border: none">&#10006;</span></span>
        </div>
        <div class="flex i-mt-30">
          <button class="fs-14 fw-600 font-poppins i-pl-35 i-pr-35 rounded bg-secondary-one text-primary-three h-10 w-40 shadow-one"
                  @click="updateProjectResponsible()" :disabled="loading">Create
          </button>
          <button
              class="focus:outline-none fs-14 fw-600 font-poppins i-pl-35 i-pr-35 i-border-1 border-secondary-one ml-5 rounded text-primary-one h-10 w-40"
              @click="closeModal()" :disabled="loading">Cancel
          </button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AddRoleModal",
  props: ['dModal', 'existingResponsible', 'projectId', 'workspaceId'],
  data() {
    return {
      placeholder: {
        responsible: 'Type email here'
      },
      roles: [
        // {value: 1, title: 'Admin'},
        {value: 2, title: 'Editor'},
        {value: 4, title: 'Viewer'}
      ],
      responsibles: [],
      responsible: {
        role: 2,
        value: '',
        user_id: '',
        email: '',
        choosed: false
      },
      projectData: {
        name: '',
        category: 'business',
        directory_id: '',
      }
    }
  },
  watch: {
    dModal: function (val) {
      if (val) {
        this.openModal();
      } else {
        this.closeModal();
      }
    }
  },
  computed: {
    ...mapGetters({
      directories: "workspace/directories",
      activeWorkspaceObj: "workspace/activeWorkspaceObj",
      workspaceUsers: "workspace/workspaceUsers",
      loading: "GET_LOADING_STATE"
    }),
    getUsers() {
      if (this.responsible.value && !this.responsible.choosed) {
        let user = JSON.parse(localStorage.getItem('_user'));
        return this.workspaceUsers.filter((item) => {
          return this.responsible.value
              .toLowerCase()
              .split(" ")
              .every((v) => {
                let id = item.w_user_id ? item.w_user_id : item.w_r_email;
                if (item.w_user_email && item.w_user_id !== user.id && this.checkUserExistence(id)) {
                  return item.w_user_email.toLowerCase().includes(v)
                } else if (item.w_r_email && item.w_user_id !== user.id && this.checkUserExistence(id)) {
                  return item.w_r_email.toLowerCase().includes(v)
                }
              });
        });
      } else {
        return [];
      }
    }
  },
  methods: {
    openModal() {
      this.$store.dispatch("workspace/getWorkspaceUser", this.workspaceId);
      this.$modal.show('project-role-modal');
    },
    closeModal() {
      this.responsible = {
        value: '',
        role: 2,
        user_id: '',
        email: '',
        choosed: false
      };
      this.projectData = {
        name: '',
        category: 'business',
        directory_id: '',
        type: '',
        workspace_id: '',
        modules: []
      }
      this.$modal.hide('project-role-modal');
      this.$emit('toggleModalMode', false);
    },
    outsideResponsible() {
      this.placeholder.responsible = 'Type email here';
    },
    getUserName(user) {
      let title = '';
      if (user.w_user_first_name) {
        title = user.w_user_first_name;
        if (user.w_user_last_name) {
          title = title + ' ' + user.w_user_last_name;
        }
      } else if (user.w_user_email) {
        title = user.w_user_email;
      } else {
        title = user.w_r_email;
      }
      return title;
    },
    chooseUser(user) {
      if (user.w_user_id) {
        this.responsible.user_id = user.w_user_id;
        this.responsible.w_role = user.w_r_role;
        this.responsible.email = user.w_user_email;
        this.responsible.value = this.getUserName(user);
      } else {
        this.responsible.value = user.w_r_email;
        this.responsible.email = user.w_r_email;
        this.responsible.w_role = user.w_r_role;
      }
      this.responsible.choosed = true;
    },
    addNewResponsible() {
      if (this.responsible.email || this.responsible.user_id) {
        if((this.responsible.w_role == 3 || this.responsible.w_role == 4) && this.responsible.w_role != this.responsible.role) {
          alert('You can not add a workspace viewer as project editor. Make the user as editor in workspace first');
          return;
        }
        this.responsibles.push(this.responsible);
        this.responsible = {
          value: '',
          user_id: '',
          email: '',
          role: this.responsible.role,
          project_id: this.projectId,
          choosed: false
        };
      } else {
        alert('Please select user from workspace!!!');
      }
    },
    getRole(role) {
      let roleItem = this.roles.find(item => parseInt(item.value) === parseInt(role));
      if (roleItem) {
        return roleItem.title;
      }
      return '';
    },
    removeResponsible(index) {
      this.responsibles.splice(index, 1)
    },
    async updateProjectResponsible() {
      const self = this;
      let data = {
        responsibles: self.responsibles,
        project_id: self.projectId
      };
      self.$store.dispatch('workspace/addProjectResponsible', data)
          .then(response => {
            if (response) {
              let workspaceId = self.workspaceId;
              self.$store.dispatch("workspace/getWorkspaceSettingsInfo", workspaceId);
              self.closeModal();
            }
          })
    },
    checkUserExistence(id) {
      let user = this.existingResponsible.find(item => item.user_id === id || item.email === id);
      return !user;
    }
  }
}
</script>

<style scoped>
option {
  border: none;
}

.module-inactive {
  color: #414146;
}

.module-inactive::placeholder {
  color: #414146;
}
</style>

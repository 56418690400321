<template>
    <div class="plans py-3 px-4 rounded border" v-if="plan">
        <span class="fw-600 fs-14 mb-1" style="color: #616161">Current Plan</span>
        <div class="flex justify-between items-center">
            <div class="flex gap-3 items-center">
                <h2 class="fw-600 fs-20 text-primary-one capitalize">{{ plan.title }}</h2>
            </div>
            <div v-if="plan.price" class="fs-14 fw-500 text-primary-one">{{ plan.price }}/<sub>mo</sub></div>
        </div>
        <div class="flex justify-end">
            <span class="badge fw-600 fs-10 flex justify-center items-center">{{ plan.price ? 'Monthly' : plan.name }}</span>
        </div>
        <h2 class="mb-3 text-primary-one"><span class="fw-600 fs-18">{{ editors.length }}</span> <span class="fw-400 fs-14" style="color: #616161;"> {{ label }} </span></h2>
        <svg width="100%" height="6" viewBox="0 0 397 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="6" rx="3" fill="#E2E2E2"/>
            <rect width="100%" height="6" rx="3" fill="#2BAD7E"/>
        </svg>

        <router-link :to="{
                name: 'workspace.subscription',
                params: {
                    workspace_id: workspace.id
                }
            }" class="mt-5 inline-flex gap-2 items-center fs-14 fw-600 text-primary-four">
            Upgrade plan
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 12L12 4" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.5 4H12V10.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </router-link>
    </div>
</template>

<script setup>
    import useAuth from '@/composable/useAuth'
    import useSubscription from '@/composable/useSubscription';
    import { computed } from 'vue';
    import { get } from 'lodash'
    import store from '@/store';
    // let { plan } = useAuth();
    const { getEditors } = useSubscription();
    const workspace = computed(() => {
        let workspace = store.getters['workspace/activeWorkspaceObj'];
        if(!workspace) {
            return {
                id: null
            };
        }
        return workspace;
    });
    const plan = computed(() =>  get(workspace.value, 'subscription.plan', {}));
    const editors = computed(() => getEditors(workspace.value));
    const label = computed(() => editors.value.length > 1 ? 'Editors on Plan' : 'Editor on Plan' )
</script>

<style scoped>
    .plans{
    filter: drop-shadow(0px 2px 8px rgba(44, 52, 65, 0.1));
    width: 455px; 
    height: 214px;
    border: 1px solid #C9C9C9;
    padding: 30px;
}
.badge{
    height: 22px;
    background: rgba(43, 173, 126, 0.1);
    border: 1px solid #2BAD7E;
    border-radius: 21px;
    color: #2BAD7E;
    padding: 4px 12px;
}
</style>
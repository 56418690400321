<template>
    <div class="leftSide_wrapper relative w-407px flex-shrink-0 mt-20">
        <div style="height: 300px;" class="overflow-y-auto">
            <div class="grid items-center text-center mb-3 px-70px">
                <h2 class="fs-24 fw-600 text-primary-one mb-2 mt-1 truncate">{{ (profileData.first_name) ? profileData.first_name : 'N/A' }} {{ (profileData.last_name) ? profileData.last_name : '' }}</h2>
                <h2 class="fs-14 fw-500 text-secondary-one mb-1">
                    {{
                        profileData.position && profileData.company ?
                            profileData.position + ', ' + profileData.company : 'N/A'
                    }}
                </h2>
                <h2 class="fs-12 fw-500 text-secondary-two mb-2">
                    {{
                        profileData.address && profileData.country ?
                        profileData.address + ' - ' + profileData.country : 'N/A'
                    }}
                </h2>
                <button class="m-auto cursor-pointer fs-14 fw-500 text-white bg-primary-four rounded py-1 px-4 button-shadow w-max hover:bg-primary-one">
                  <router-link :to="{ name: 'workspace.settings' }">Edit profile</router-link>
                </button>
            </div>
<!--            <p class="px-70px fs-12 fw-400 text-black text-center mb-3">-->
<!--                Talks about #thoughtleadership, #disruptivetechnology, #digitaltransformation, and #communitybasedpracticeTalks-->
<!--            </p>-->
<!--            <div class="px-70px text-center text-primary-one leading-5">-->
<!--                <h2 class="fs-14 fw-500">Instrat Technology ApS</h2>-->
<!--                <p class="fs-10 fw-400">Copenhagen, Capital Region, Denmark</p>-->
<!--                <h3 class="fs-14 fw-600 text-primary-four">Contact info</h3>-->
<!--                <p class="fs-14 fw-600">www.instrat360.com</p>-->
<!--            </div>-->
        </div>
        <div class="socialMedia px-70px relative flex justify-center gap-6 mb-1 text-secondary-one mt-5 pt-3 pb-11">
            <a href="#"><IconFb /></a>
            <a href="#"><IconGoogle /></a>
            <a href="#"><IconLinkedin /></a>
            <a href="#"><IconTwitter /></a>
            <a href="#"><IconYoutube /></a>
        </div>
    </div>
</template>

<script setup>
    import store from '@/store'
    import { onMounted, computed } from 'vue'
    import IconFb from '@/components/workspace/icons/IconFb.vue'
    import IconGoogle from '@/components/workspace/icons/IconGoogle.vue'
    import IconLinkedin from '@/components/workspace/icons/IconLinkedin.vue'
    import IconTwitter from '@/components/workspace/icons/IconTwitter.vue'
    import IconYoutube from '@/components/workspace/icons/IconYoutube.vue'

    const profileData = computed(()=>{
        return store.getters['profile/data']
    })
</script>

<style scoped>
    .leftSide_wrapper::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 90%;
        border-right: 1px solid #c9c9c9;
    }
    .socialMedia::before{
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 85%;
        border-top: 1px solid #c9c9c9;
    }
</style>

<template>
    <label class="absolute bg-gray-400 rounded overflow-hidden cursor-pointer profilePic bottom-0 transform translate-y-1/2 z-10">
        <input class="absolute" hidden type="file" @input="uploadImage">
        <img :alt="profileData?.first_name?.charAt()" v-if="image" :src="image" />
        <img :alt="profileData?.first_name?.charAt()" v-else src="@/assets/images/icons/profile/profile.png" />
    </label>
</template>

<script setup>
    import store from '@/store'
    import { computed, onMounted, ref, watchEffect } from 'vue'
    import UploadService from "@/store/services/uploadService";

    import { UseProfile } from '@/components/workspace/userProfile/UseProfile'
    const { uploadProfileImage } = UseProfile()

    const image = ref()

    const profileData = computed(()=>{
        return store.getters['profile/data']
    })

    watchEffect(()=>{
        image.value = profileData.value?.image
    })

    const uploadImage = async (e) =>
    {
        let reader = new FileReader()
        reader.onload = (e) => image.value = e.target.result
        reader.readAsDataURL(e.target.files[0])

        let uploadService = new UploadService(e);
        if (!uploadService.fileValidation(1024)) return false; //size KB
        let formData = uploadService.fieldAppend();
        uploadProfileImage(formData)
    }
</script>

<style scoped>
    .profilePic{
        width: 152px;
        height: 152px;
        left: 123px;
        filter: drop-shadow(0px 7.01539px 17.5385px rgba(35, 39, 47, 0.13));
    }
    .profilePic img{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
</style>

<template>
    <div>
        <div class="profile_container rounded overflow-hidden bg-primary-three mt-9">
            <Banner>
                <UserImage />
            </Banner>
            <div class="flex">
                <BasicInfo />
                <Content />
            </div>
        </div>
    </div>
</template>

<script setup>
    import store from '@/store'
    import Banner from '@/components/workspace/userProfile/Banner.vue'
    import UserImage from '@/components/workspace/userProfile/UserImage.vue'
    import BasicInfo from '@/components/workspace/userProfile/BasicInfo.vue'
    import Biography from '@/components/workspace/userProfile/Biography.vue'
    import Content from '@/components/workspace/userProfile/Content.vue'
    import { onMounted } from 'vue'

    onMounted(()=>{
        // load profile data to vuex
        store.dispatch("profile/fetchProfileData")
    })
</script>
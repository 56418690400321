<template>
    <div class="relative banner_wrapper" :style="{backgroundImage: `url(${(profileData.banner) ? profileData.banner : bg_img})`}">
        <label class="absolute top-5 right-5 opacity-70 cursor-pointer">
            <IconPencil />
            <input type="file" hidden @input="uploadBanner" />
        </label>
        <slot />
    </div>
</template>

<script setup>
    import axios from 'axios'
    import store from '@/store'
    import IconPencil from '../icons/IconPencil.vue'
    import { ref, computed } from 'vue'
    const bg_img = ref("https://images.unsplash.com/photo-1506799890340-a79e76e3c2ce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1936&q=80")
   
    const uploadBanner = (e) => 
    {
        let reader = new FileReader()
        reader.onload = (e) => bg_img.value = e.target.result
        reader.readAsDataURL(e.target.files[0])

        const formData = new FormData()
        formData.append('image_url', e.target.files[0])
        axios.post('profile-banner', formData).then(res => {
            if(!res.status) return;
            store.dispatch("profile/fetchProfileData")
        })
    } 
    
    const profileData = computed(() => {
        return store.getters['profile/data']
    })
</script>

<style scoped>
    .banner_wrapper{
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 216px;
    }
</style>
<template>
    <div class="relative h-5 w-5">
        <DotVerticalIcon @click.native="show = !show" class="w-5 h-5 cursor-pointer" />
        <div v-if="show" class="right-5 -top-1 absolute rounded bg-white px-4 py-2 fw-500 text-secondary-tow fs-14 shadow-one z-10">
            <button v-if="canDelete(activeWorkspace.cans)" @click="show = !show, $emit('onClickDelete', uerId)">Delete</button>
        </div>
    </div>
</template>

<script setup>
    import { computed, ref } from 'vue'
    import usePermission from '@/composable/usePermission.js'
    import store from '@/store'
    const { canCreate, canEdit, canRead, canUpdate, canDelete } = usePermission()

    // eslint-disable-next-line no-undef
    const props = defineProps({
        uerId: [Number, String]
    })
    const show = ref(false)

    const activeWorkspace = computed(() => {
        return store.getters['workspace/activeWorkspaceObj']
    })
</script>
<template>
    <div class="plans py-3 px-4 rounded border">
        <span class="fw-600 fs-14 mb-1" style="color: #616161">Next Billing</span>
        <div class="flex justify-between items-center mb-6">
            <div class="flex gap-3 items-center">
                <h2 class="fw-600 fs-20 text-primary-one">{{ nextPaymentDate }}</h2>
            </div>
            <div class="fs-14 fw-500 text-primary-one">${{ price }}</div>
        </div>
        <h2 class="mb-3 text-primary-one">
            <span class="fw-600 fs-18">{{ remaining }}</span> <span class="fw-400 fs-14" style="color: #616161;">days remaining</span>
        </h2>
        <svg width="100%" height="6" viewBox="0 0 397 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="100%" height="6" rx="3" fill="#E2E2E2"/>
            <rect :width="progress" height="6" rx="3" fill="#2BAD7E"/>
        </svg>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import store from '@/store'
    import useSubscription from '@/composable/useSubscription';
    import useAuth from '@/composable/useAuth';
    import { get } from 'lodash'
    import { getDaysInMonth, formatDistance, format } from 'date-fns'

    // let { remaining, progress } = useAuth();
    const { getEditors } = useSubscription();
    const workspace = computed(() => store.getters['workspace/activeWorkspaceObj']);
    const editors = computed(() => getEditors(workspace.value));
    const plan = computed(() =>  get(workspace.value, 'subscription.plan', {}));
   
    const nextPaymentDate = computed(() =>   format(new Date(get(workspace.value, 'subscription.subscription_ends_at', null)), 'dd MMMM, yyyy'));
    const remaining = computed(() => {
        if(!nextPaymentDate.value) {
            return null;
        }
        let amount = formatDistance(new Date(nextPaymentDate.value), new Date());
        //Case: if distance amount is greater then 1 month,
        return parseInt(amount.replace(/\D/g, ""));
    });

    const progress = computed(() => {
        let totalDayOfMonth =  getDaysInMonth(new Date());
        let totalWidth = 397;
        return (totalWidth / totalDayOfMonth) * remaining.value;
    });

    const price = computed(() => {
        let price = plan.value.price ? parseInt(plan.value.price.replace(/[^\w ]/, '')) : 0;
        return price * editors.value.length;
    });
</script>
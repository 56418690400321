<template>
    <div class="biography_wrapper relative">
        <p @keyup="(e) => {DataForUpdate = e.target.innerText; saveBiography();}" class="fs-17 fw-300 text-primary-one" :contenteditable="contenteditable">{{ biography }}</p>
    </div>
</template>

<script setup>
    import store from '@/store'
    import { ref, computed, onMounted } from 'vue'
    import { UseProfile } from './UseProfile'
    const { updateProfileData } = UseProfile()

    const props = defineProps(["contenteditable"])
    const DataForUpdate = ref()

    const biography = computed(()=>{
        let data = store.getters['profile/data']
        return data?.biography;
    })
    
    onMounted(()=>{
        DataForUpdate.value = biography.value
    })

    let timeoutId = null
    const saveBiography = ()=>{
        clearTimeout(timeoutId)
        timeoutId = setTimeout(()=>{
            updateProfileData({biography: DataForUpdate.value})
        }, 2000)
    }
</script>


<style scoped>
    .biography_wrapper{
        line-height: 34px;
        height: 220px;
        overflow-y: auto;
    }
    .biography_wrapper>p:empty {
        background: #f5f5f5;
        height: 34px;
        position: relative;
    }
    
    .biography_wrapper>p:empty::before{
        content: 'Write here...'
    }
</style>
import store from '@/store'
import axios from 'axios'

export default function useRole() {
    const updateRole = (roleId, responsibleId) => {
        store.dispatch('workspace/updateRole', {
            id: responsibleId,
            role: roleId
        });
    }

    const deleteWorkspaceResponsible = (workspace_id, responsibleId) => {
        if (confirm('Do you want to delete this?')) {
            return axios.delete(`workspaces/responsibles/${responsibleId}`)
            .then(response => {
                store.dispatch("workspace/fetchWorkspaces", {workspace_id})
                return response;
            })
        }
    }
    
    const deleteWorkspaceMultipleResponsible = (workspace_id, responsibleIds) => {
        if (confirm('Do you want to delete this?')) {
            store.dispatch("workspace/deleteWorkspaceResponsibles", {workspace_id, responsibleIds})
            .then(response => {
                store.dispatch("workspace/fetchWorkspaces", {workspace_id})
                return response;
            })
        }
    }

    const deleteProjectResponsible = (responsibleId, workspaceId) => {
        if (confirm('Do you want to delete this?')) {
            store.dispatch("workspace/deleteProjectResponsible", responsibleId,).then(response => {
                if (response) {
                    store.dispatch("workspace/getWorkspaceSettingsInfo", workspaceId)
                }
            });
        }
    }
    
    const deleteProjectMultipleResponsible = (responsibleIds, workspaceId) => {
        if (confirm('Do you want to delete this?')) {
            store.dispatch("workspace/deleteProjectResponsibles", responsibleIds,).then(response => {
                if (response) {
                    store.dispatch("workspace/getWorkspaceSettingsInfo", workspaceId)
                }
            });
        }
    }

    return {
        updateRole,
        deleteProjectResponsible,
        deleteProjectMultipleResponsible,
        deleteWorkspaceResponsible,
        deleteWorkspaceMultipleResponsible
    }
}
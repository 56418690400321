<template>
    <div>
        <form action="#" id="profileSettings" class="grid gap-y-6 -mt-3" style="width: 1020px;">
            <div class="form-control">
                <div>
                    <label class="fs-14 fw-500 mb-1" for="first_name">First name</label>
                    <input id="first_name" v-model="profileData.first_name" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="text" placeholder="Type here">
                </div>
                <div>
                    <label class="fs-14 fw-500 mb-1" for="last_name">Last name</label>
                    <input id="last_name" v-model="profileData.last_name" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="text" placeholder="Type here">
                </div>
            </div>

            <div class="form-control">
                <div>
                    <label class="fs-14 fw-500 mb-1" for="occupation">Occupation</label>
                    <input id="occupation" v-model="profileData.position" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="text" placeholder="Type here">
                </div>
                <div>
                    <label class="fs-14 fw-500 mb-1" for="company">Company</label>
                    <input id="company" v-model="profileData.company" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="text" placeholder="Type here">
                </div>
            </div>

            <div class="form-control">
                <div>
                    <label class="fs-14 fw-500 mb-1" for="email">E-mail</label>
                    <input id="email" :value="profileData.email" disabled class="block cursor-not-allowed w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="email" placeholder="Type here">
                </div>
                <div>
                    <label class="fs-14 fw-500 mb-1" for="phone">Phone</label>
                    <input id="phone" v-model="profileData.phone" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="text" placeholder="Type here">
                </div>
            </div>

            <div class="form-control">
                <div>
                    <label class="fs-14 fw-500 mb-1" for="address">Address</label>
                    <input id="address" v-model="profileData.address" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="text" placeholder="Type here">
                </div>
                <div>
                    <label class="fs-14 fw-500 mb-1" for="cityOrCountry">City/Country</label>
                    <input id="cityOrCountry" v-model="profileData.country" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="text" placeholder="Type here">
                </div>
            </div>

            <div class="mt-2">
                <h2 class="fs-14 fw-600 text-primary-one">Account Settings</h2>
                <hr class="border-secondary-two mt-2"/>
            </div>

            <div class="form-control">
                <div>
                    <label class="fs-14 fw-500 mb-1" for="accountEmail">E-mail</label>
                    <input id="accountEmail" @input="setMail" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="email" placeholder="Type here">
                </div>
                <div>
                    <label class="fs-14 fw-500 mb-1" for="currentPassword">Current Password</label>
                    <input id="currentPassword" v-model="currentPassword" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="password" placeholder="Type here">
                </div>
            </div>

            <div class="form-control">
                <div>
                    <label class="fs-14 fw-500 mb-1" for="newPassword">New Password</label>
                    <input id="newPassword" v-model="newPassword" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="password" placeholder="Type here">
                </div>
                <div>
                    <label class="fs-14 fw-500 mb-1" for="retypePassword">Retype Password</label>
                    <input id="retypePassword" v-model="confirmPassword" class="block w-full rounded border border-secondary-five py-2 px-4 bg-transparent" type="password" placeholder="Type here">
                </div>
            </div>

            <button @click="saveProfileData" class="py-2 px-6 fs-14 fw-600 bg-primary-three rounded border border-primary-four shadow" style="width: fit-content;">Save Changes</button>
        </form>
    </div>
</template>


<script setup>
    import store from '@/store'
    import { ref, computed } from 'vue'
    import { UseProfile } from '@/components/workspace/userProfile/UseProfile.js'
    const { updateProfileData } = UseProfile()

    /* const profileData = ref({
        first_name: '',
        last_name: '',
        position: '', //occupation
        company: '', //City/Country
        cont_email: '',
        phone: '',
        address: '',
        country: '',
        email: ''
    }) */


    const currentPassword = ref()
    const newPassword     = ref()
    const confirmPassword = ref()
    const profileInfo     = ref()
    const email     = ref()

    const profileData = computed(()=>{
        email.value = profileData.email
        return store.getters['profile/data']
    })

    const setMail = (e) => email.value = e.target.value

    const saveProfileData = (e) => {
        e.preventDefault()
        let data = {}
        if (newPassword.value !== confirmPassword.value) {
            alert('Password not matched')
            return false
        }

        if (email.value.trim() != profileData.value?.email) {
            if (!currentPassword.value) {
                alert('Enter current password to change email')
                return false
            }
            currentPassword.value = ''
            data.email = email.value
            data.currentPassword = currentPassword.value
        }

        if (currentPassword.value && newPassword.value) {
            data.currentPassword = currentPassword.value
            data.newPassword = newPassword.value
        }

        data.first_name = profileData.value.first_name
        data.last_name  = profileData.value.last_name
        data.position   = profileData.value.position
        data.company    = profileData.value.company
        data.address    = profileData.value.address
        data.phone      = profileData.value.phone
        data.country    = profileData.value.country

        updateProfileData(data)
    }

</script>


<style scoped>
    .form-control{
        display: grid;
        grid-template-columns: 488px 488px;
        gap: 42px;
    }
    ::placeholder {
        color: #8d8d8d;
    }
    .shadow{
        box-shadow: 0px 6px 15px rgba(35, 39, 47, 0.13);
    }
</style>

<template>
    <div class="w-full mt-20 pt-2 pr-20 overflow-x-auto">
        <div class="pl-30px flex relative user_nav gap-12 pb-3">
            <h2 v-for="(item, index) in nav" 
                :key="index" 
                @click="loadComponent(item); isContentEditable = false"
                class="cursor-pointer fs-14 fw-600 color-primary-one opacity-40 hover:opacity-100"
                :class="item.isActive&&'opacity-100'">{{ item.title }}</h2>

            <button 
                v-show="componentName==Biography"
                @click="isContentEditable = !isContentEditable"
                class="cursor-pointer absolute right-0 top-0"
                :class="isContentEditable ? 'opacity-100' : 'opacity-40'"
            >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.24112 16.8749H3.75C3.58424 16.8749 3.42527 16.8091 3.30806 16.6918C3.19085 16.5746 3.125 16.4157 3.125 16.2499V12.7588C3.125 12.6767 3.14117 12.5954 3.17258 12.5196C3.20398 12.4438 3.25002 12.3749 3.30806 12.3168L12.6831 2.94185C12.8003 2.82464 12.9592 2.75879 13.125 2.75879C13.2908 2.75879 13.4497 2.82464 13.5669 2.94185L17.0581 6.43296C17.1753 6.55017 17.2411 6.70915 17.2411 6.87491C17.2411 7.04067 17.1753 7.19964 17.0581 7.31685L7.68306 16.6918C7.62502 16.7499 7.55612 16.7959 7.48029 16.8273C7.40447 16.8587 7.32319 16.8749 7.24112 16.8749Z" stroke="#1C1C1D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.625 5L15 9.375" stroke="#1C1C1D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.45938 16.8349L3.16406 12.5396" stroke="#1C1C1D" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </div>

        <div :class="componentName!=UserProjects&&'content'" class="relative p-30px pr-0">
            <component :contenteditable="isContentEditable" :is="componentName" />
        </div>

    </div>
</template>
<script setup>
    import Biography from './Biography.vue'
    import UserProjects from './UserProjects.vue'
    import Analytics from './Analytics/index.vue'
    import { onMounted, ref, watchEffect } from 'vue'

    const props = defineProps(["profileData"])

    const data = ref()
    const nav  = ref()
    const componentName = ref()
    const componentData = ref()
    const isContentEditable = ref(false)


    onMounted(()=>
    {
        componentName.value = Biography
        componentData.value = props.profileData?.biography

        nav.value = [
        {
            title: 'Biography',
            component: Biography,
            isActive: true,
            data: ''
        },
        {
            title: 'Published Projects',
            component: UserProjects,
            isActive: false,
            data: ProjectsData
        },
        {
            title: 'Analytics',
            component: Analytics,
            isActive: false,
            data: ''
        }]
    })

    const ProjectsData = [
        {
            id: 1,
            bg:  "https://images.unsplash.com/photo-1659990234229-21bd3c69ba06?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxMHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
            title:  "Build your Own Ecosystem",
            description: "Complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company.",
            settings:  "{download: 150, love: 5}"
        },
        {
            id: 2,
            bg: "https://images.unsplash.com/photo-1660006795957-1af9c6de897e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxOXx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
            title: "Partner Onboarding System",
            description: "Complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company.",
            settings: "{download: 150, love: 155}"
        },
        {
            id: 3,
            bg: "https://images.unsplash.com/photo-1660001547784-c13f484714be?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzM3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
            title: "Inventory Overview",
            description: "Complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company.",
            settings: "{download: 250, love: 125}"
        },
        {
            id: 4,
            bg: "https://images.unsplash.com/photo-1660002561318-6ef0a0ae1f04?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwzOHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60",
            title: "Build your Own Ecosystem",
            description: "Complete blueprint for corporate maturity and innovation. Including capabilities, tactices and metodologies ready to deploy in you company.",
            settings: "{download: 150, love: 5}"
        }
    ]

    const loadComponent = (item) => 
    {
        nav.value.map(_item=>_item.isActive = false)
        item.isActive = true
        componentName.value = item.component
        componentData.value = item.data
    }
</script>

<style scoped>
    .user_nav::before{
        content: '';
        position: absolute;
        bottom: 0;
        left: -30px;
        width: 103%;
        border-bottom: 1px solid #c9c9c9;
    }

    .content::before{
        content: '';
        position: absolute;
        bottom: 0;
        left: -20px;
        width: 102%;
        border-bottom: 1px solid #C9C9C9;
    }
</style>
import { computed } from "vue";
import store  from '@/store';
import { isEmpty } from "lodash";
import { addMonths, format, formatDistance, getDaysInMonth } from 'date-fns'


export default function useAuth() {
    const auth = computed(() => store.state.auth.auth);
    const fullPlan = computed(() => auth.value.plan);
    const plan = computed(() => {
       let plan = Object.assign({}, auth.value.plan);
       return !isEmpty(plan) ? plan : null;
    });
    const nextBillingDate = computed(() => {
        if(!auth.value.subscription) {
            return null;
        }
        return format(new Date(auth.value.subscription.subscription_ends_at), 'dd MMMM, yyyy');
    });

    const remaining = computed(() => {
        if(!nextBillingDate.value) {
            return null;
        }
        let amount = formatDistance(new Date(nextBillingDate.value), new Date());
        //Case: if distance amount is greater then 1 month,
        return parseInt(amount.replace(/\D/g, ""));
    });

    const progress = computed(() => {
        let totalDayOfMonth =  getDaysInMonth(new Date());
        let totalWidth = 397;
        return (totalWidth / totalDayOfMonth) * remaining.value;
    });

    return {
        remaining,
        progress,
        auth,
        plan,
        fullPlan,
        nextBillingDate
    }
}